._slider {
	overflow: hidden;
	
	.wrapper {
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		height: 43vw;
		min-height: 350px;
		border-bottom: 3px solid $white;
	}
	&.-darken {
		picture, .pimcore_tag_image {
			&::after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: #000;
				opacity: 0.4;
			}
		}
	}
	.caption {
		position: relative;
		margin-left: 20px;
		margin-right: 10px;
		z-index: 10;
		max-width: 450px;
		@include media-breakpoint-up(sm) {
			margin-left: 12vw;
		}
		@include media-breakpoint-up(hd) {
			margin-left: 6vw;
		}
		@media(min-width: 1550px) {
			margin-left: 20px;
		}
	}
	h1, h2 {
		font-style: italic;
	}
	.teaser {
		border-left: 2px solid $white;
		margin-left: 35px;
		padding-left: 25px;
		font-size: $h2-font-size-mobile;
		@include desktop() {
			font-size: $h2-font-size;
		}
	}
	.carousel-control-prev-icon {
		transform: rotate(180deg);
	}
	.carousel-indicators {
		margin-bottom: 2rem;
		
		li {
			border-radius: 12px;
		}
	}
}