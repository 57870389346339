.typeahead__container {
	position: relative;
	
	.typeahead__field {
		width: 295px;
	}
	.typeahead__button {
		width: 52px;
	}
	.typeahead__list {
		display: none;
		position: absolute;
		right: 0;
		z-index: 1000;
		width: 100%;
		min-width: 400px;
		padding: 0;
		margin: 15px 0 0;
		list-style: none;
		background-color: $white;
		border: 1px solid darken($light, 10%);
		border-radius: 2px;
		font-size: 1.25rem;
		
		& > li {
			border-top: solid 1px darken($light, 10%);
			
			&:first-child {
				border-top: none;
			}
		}
		.typeahead__item > a {
			display: block;
			padding: 0.6rem 1rem 0.5rem;
			text-decoration: none;
			
			&:hover, &:focus{
				background-color: $light;
			}
		}
		.typeahead__group-fulltext {
			background-color: $light;
			border-top-width: 3px;
		}
	}
	&.result .typeahead__list {
		display: block;
	}
}