._text {
	margin-top: 50px;
	margin-bottom: 50px;
	position: relative;
	
	&.-border {
		border: 1px solid #ababab;
		
		.wysiwyg {
			margin: 25px;
		}
	}
}

aside {
	._text {
		.wysiwyg {
			margin: 25px;
		}
	}
}