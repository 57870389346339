header {
	@include mobile() {
		#offcanvas-menu {
			background-color: $white;
			position: fixed;
			top: 0;
			left: 100%;
			width: 310px;
			height: 100vh;
			z-index: 9999;
			overflow-y: auto;
			transform: translate3d(0, 0, 0);
			transition: all 0.4s cubic-bezier(.86, 0, .07, 1);

			&.active {
				transform: translate3d(-100%, 0, 0);
				box-shadow: 0 0 10px $gray-700;
			}
			.logo {
				border-bottom: 1px solid darken($light, 2%);
				height: 105px;
				padding: 20px 30px;
			}
			nav.menu {
				position: relative;
				width: 100%;
				transform: translate3d(0, 0, 0);
				transition: all 0.4s cubic-bezier(.86, 0, .07, 1);

				&.off-view {
					transform: translate3d(-100%, 0, 0);
				}
				ul {
					margin: 0;
					padding: 0;
					list-style: none;

					li {
						display: block;
					}
				}
			}
			ul.menu-submenu {
				position: absolute;
				top: 0;
				left: 100%;
				width: 100%;
				height: auto;
				transform: translate3d(100%, 0, 0);
				transition: all .4s cubic-bezier(.86, 0, .07, 1);

				&.in-view {
					transform: translate3d(0, 0, 0);
				}
				&.off-view {
					transform: translate3d(-100%, 0, 0);
				}
			}
			.menu-item {
				border-bottom: 1px solid darken($light, 2%);
				display: block;
				width: 100%;

				&.home, &.social {
					display: none;
				}
				.menu-link {
					display: block;
					padding: 10px 30px;
					position: relative;
					text-decoration: none;
				}
				.submenu-toggle {
					position: absolute;
					top: 0;
					right: 0;
					width: 44px;
					height: 44px;
					background: url(/img/icon-chevron.svg) no-repeat center;
					transform-origin: center;
					transform: rotate(0.75turn);
					border-top: 1px solid darken($light, 2%);
				}
			}
			.back-btn {
				background-color: $light;
				position: relative;

				&::before {
					content: " ";
					position: absolute;
					left: 20px;
					width: 30px;
					height: 44px;
					background: url(/img/icon-chevron.svg) no-repeat center;
					transform-origin: center;
					transform: rotate(0.25turn);
				}
				.menu-link {
					padding-left: 50px;
				}
				&.-close {
					&::before {
						left: 22px;
						background: url(/img/icon-close.svg) no-repeat center;
						transform: none;
					}
					.menu-link {
						padding-left: 52px;
					}
				}
			}
			.form-inline {
				flex-wrap: nowrap;
				padding: 4px 18px;
				border-bottom: 1px solid darken($light, 2%);
				background-color: $light;

				.form-control {
					background-color: transparent;
				}
				button {
					border: none;
					background: none transparent;
				}
			}
		}
		.menu-wrapper {
			position: relative;
			width: 100%;
			overflow: hidden;
		}
		.navbar-toggler {
			span {
				display: inline-block;
				margin-right: 10px;
				@media (max-width: 389px) {
					display: none;
				}
			}
		}
	}
	@include desktop() {
		margin-top: 15px;

		#header {
			.contact-row {
				margin-top: 45px;
				margin-bottom: 60px;

				.big-phone {
					display: flex;
					text-decoration: none;

					span {
						font-size: $h1-font-size;
					}
				}
			}
			@include ie11() {
				.iefix {
					min-width: 470px;
				}
			}
		}
		#offcanvas-menu {
			border-top: 1px solid $light;
			border-bottom: 1px solid $light;

			.menu-wrapper {
				@include make-container();
				max-width: 1400px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: nowrap;
			}
			.logo {
				display: none;
			}
			nav.menu, .form-inline {
				padding-top: 10px;
				padding-bottom: 10px;
			}
			nav.menu {
				display: flex;
				height: 112px!important;

				.back-btn {
					display: none !important;
				}
				.menu-item.has-children {
					position: relative;

					&:hover > ul.menu-submenu {
						display: block;
						animation: submenu-show .1s linear; //cubic-bezier(.68, -.55, .265, 1.55);
						z-index: 999;
					}
					ul.menu-submenu {
						display: none;
						position: absolute;
						top: 100%;
						left: 0;
						margin-top: -7px !important;
						padding: 10px 0 !important;
						border: 1px solid $border-color;
						border-radius: $border-radius;
						background-color: $white;
						box-shadow: 0 7px 22px -5px rgba(darken($gray-600, 4%), .2);

						> li {
							display: block;
							position: relative;
							font-family: "Fago";
							> a {
								display: block;
								padding: 5px 20px !important;
								font-size: 1.125rem;
								white-space: nowrap;
							}
							&.has-children {
								> a {
									padding-right: 35px !important;
									&::after {
										display: block;
										position: absolute;
										top: 50%;
										right: 18px;
										width: 0;
										height: 0;
										margin-top: -4px;
										border-top: .3em solid transparent;
										border-bottom: .3em solid transparent;
										border-left: .32em solid;
										content: '';
									}
								}
							}
							> ul.menu-submenu {
								top: -4px;
								left: 100%;
								margin-left: -5px;
							}
						}
					}
				}
			}
			.menu-item {
				font-size: $h4-font-size;
				font-family: "FagoBold";
				display: flex;
				align-items: center;

				&.home {
					.menu-link {
						padding: 0 10px 0 0;
					}
				}
				.menu-link {
					padding: 5px 15px;
					text-decoration: none;
				}
			}
			.form-inline {
				border-left: 1px solid $light;
				border-right: 1px solid $light;
				flex-wrap: nowrap;
				align-self: stretch;

				.form-control {
					background-color: transparent;
					//margin-left: 4px;
				}
				button {
					border: none;
					background: none transparent;
					margin-right: 10px;
				}
			}
		}
	}
}

nav.submenu {
	font-size: $h5-font-size;
	padding-bottom: 50px;

	.active-parent {
		margin-bottom: 25px;

		a {
			color: lighten($body-color, 30%);
			text-transform: uppercase;
		}
	}
	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			padding: 7px 10px 7px 30px;
			margin-right: 20px;
			border-bottom: 1px solid darken($light, 10%);
			position: relative;

			&.has-children, &.active, &:hover, &:focus, &:active {
				&::before {
					content: " ";
					position: absolute;
					left: 0;
					width: 30px;
					height: 30px;
					transform-origin: center;
				}
			}
			&.has-children::before {
				top: 50%;
				margin-top: -15px;
				background: url(/img/icon-chevron_gray.svg) no-repeat center;
				transform: rotate(0.75turn);
			}
			&.active, &:hover, &:focus, &:active {
				&::before {
					top: 50%;
					margin-top: -15px;
					background: url(/img/icon-chevron.svg) no-repeat center;
					transform: rotate(0.75turn);
				}
				& > a {
					color: $black;
				}
			}
			&.active.has-children::before {
				top: 0;
				margin-top: 7px;
				transform: none;
			}
			a {
				display: block;
				color: lighten($body-color, 30%);

				@include hover() {
					text-decoration: none;
				}
			}
		}
		ul li {
			border-bottom: none;
		}
	}
}

// Sub Menu Animation
@keyframes submenu-show {
	from {
		//transform: scale(.9);
		opacity: 0;
	}
	to {
		//transform: scale(1);
		opacity: 1;
	}
}