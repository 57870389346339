@charset "UTF-8";

$slick-font-path: "/fonts/" !default;
$slick-loader-path: "/img/" !default;
$slick-dot-color: $gray-300 !default;
$slick-dot-color-active: $gray-500 !default;
$slick-dot-size: 23px !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

/* Slider */

.slick-slider {
	.slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
	.slick-slide {
		margin: 0 5px;
	}
	figure {
		margin-bottom: 0;
	}
}


/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 30px;
    width: 30px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
	background: url(/img/icon-chevron.svg) no-repeat center;
	background-size: cover;
    top: 50%;
    padding: 0;
    border: none;
    outline: none;
	opacity: 0.7;
	z-index: 1;
    &:hover, &:focus {
        outline: none;
        opacity: 1;
    }
    &.slick-disabled {
        opacity: 0.2;
    }
}

.slick-prev {
    left: -25px;
	transform: translate(0, -50%) rotate(90deg);
}

.slick-next {
    right: -25px;
	transform: translate(0, -50%) rotate(-90deg);
}


/* Dots */

.slick-dotted.slick-slider {
    padding-bottom: 85px;
}
.slick-dots {
    position: absolute;
    bottom: 0;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
	
    li {
        display: inline-block;
        height: $slick-dot-size;
        width: $slick-dot-size;
        margin: 0 6px;
        padding: 0;
        cursor: pointer;
		
        button {
            border: 0;
            background: $slick-dot-color;
            display: block;
            height: $slick-dot-size;
            width: $slick-dot-size;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 0px;
            cursor: pointer;
			border-radius: 12px;
			
            &:hover, &:focus {
                outline: none;
            }
        }
        &.slick-active button {
            background: $slick-dot-color-active;
        }
    }
}
