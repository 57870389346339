.cookie-banner {
	width: 350px;
	max-width: 88vw;
	padding: 20px 30px;
	position: fixed;
	bottom: 50px;
	background-color: #fff;
	z-index: 2000;
	right: 20px;
	box-shadow: 0 0 7px #888;
	
	p {
		font-size: 11px;
		text-align: justify;
	}
	button {
		display: block;
		width: 100%;
	}
}
@media (max-width:299px) {
	.cookie-banner {
		max-width: 100%;
		bottom: 1rem;
		right: 1rem;
		left: 1rem;
	}
}

//seite settings
.cookie-box {
	background-color: #f2f2f2;
	border: 1px solid #ddd;
	border-radius: 5px;
	padding: 0 1rem;
	margin-top: 1rem;
	
	& + h2 {
		margin-top: 2rem;
	}
	& > h3 {
		margin-top: 12px;
	}
}

@keyframes pulsate {
	50% {
		background-color: #dff0d8;
		border-color: #d6e9c6;
	}
	100% {
		background-color: #f2f2f2;
		border-color: #ddd;
	}
}
.cookie-box:focus-within {
	animation: pulsate 1s ease-in-out;
}
