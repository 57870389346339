.-white {
	&, h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6,
	a {
		color: $white;
	}
}

.subtitle {
	margin-bottom: -20px;
    color: $text-muted;
}

h1, .subtitle {
	+ time {
		margin-top: 50px;
		display: block;
		font-size: 1.25rem;
		margin-bottom: -30px;
	}
}

.container-fluid, .breadcrumb {
	max-width: 1400px;
}

.modal {
	font-size: 1.25rem;
	
	.badge {
		font-size: 100%;
		min-width: 40px;
	}
}

.red-bar {
	background-color: $primary;
	min-height: 66px;
}

nav[aria-label="breadcrumb"] {
	background-color: $breadcrumb-bg;
}
.breadcrumb {
	@include make-container();
	min-height: 12px;
	
	.breadcrumb-item {
		padding-left: 1.5rem !important;
		text-transform: uppercase;
		letter-spacing: 1.7px;
		
		&::before {
			font-size: 20px;
			line-height: 1;
			padding-right: 1rem;
		}
		&:last-child::before {
			color: #930116;
		}
	}
}

picture.fullscreen {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
picture.fullscreen img, img.fullscreen {
	position: absolute;
	min-width: 1000%;
	min-height: 1000%;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%) scale(0.1);
}

//bilder im wysiwyg immer fliud machen
.wysiwyg {
	font-size: 1.25rem;
	
	a:not(.btn) {
		color: $primary;
	}
	img {
		@include img-fluid();
		&[style*="float: left"], &[style*="float:left"] {
			margin-right: 15px;
		}
		&[style*="float: right"], &[style*="float:right"] {
			margin-left: 15px;
		}
	}
}

span, a {
	&.mail, &.phone, &.fax {
		position: relative;
		display: inline-block;
		padding-left: 30px;
		white-space: nowrap;
		
		&::before {
			content: "";
			position: absolute;
			left: 1px;
			top: 50%;
			margin-top: -14px;
			width: 28px;
			height: 28px;
			display: inline-block;
			background-size: cover;
		}
	}
	&.mail::before {
		background-image: url(/img/icon-email.svg);
	}
	&.phone::before {
		background-image: url(/img/icon-phone.svg);
	}
	&.fax::before {
		background-image: url(/img/icon-fax.svg);
	}
}
.inverse {
	span, a {
		&.mail::before {
			background-image: url(/img/icon-email.svg?color=ffffff);
		}
		&.phone::before {
			background-image: url(/img/icon-phone.svg?color=ffffff);
		}
		&.fax::before {
			background-image: url(/img/icon-fax.svg?color=ffffff);
		}
	}
}

.mime {
	margin-left: 4px;
}

.module {
	margin-top: 50px;
	margin-bottom: 50px;
}

aside {
	.module:first-child {
		margin-top: 0 !important;
	}
}

fieldset {
	margin-top: 15px;
	
	legend {
		color: $primary;
		font-size: 1.5rem;
	}
}

figure {
	display: table;
	
	&.lightbox > a {
		text-decoration: none;
	}
	figcaption {
		caption-side: bottom;
		display: table-caption;
		padding-top: 5px;
	}
}
.slick figure {
	display: block;
}

.modal-dialog .alert-success {
		background-color: white;
}