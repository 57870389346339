
$dropdown-outline-color: #EE1C25; 
.filter-label {
	background-color: $primary;
	color: $white;
	font-family: FagoBold;
}
.stellenangebote {

	hyphens: manual;
	position: relative;
	top: -3em;

	.list-group {
		.list-group-item {
			position: relative;
			font-size: 1.25rem;
			padding: 1rem 4rem 1rem 2rem;
			hyphens: auto;
			background-color: $light;
			margin-bottom: 1em;

			&:last-child {
				border-bottom-width: 1px!important;
			}
			
			&:not(.no-results):after {
				content: "";
				position: absolute;
				right: 30px;
				top: 50%;
				margin-top: -15px;
				height: 30px;
				width: 30px;
				background: url(/img/icon-more.svg) no-repeat center;
			}
			
			h3 {
				//font-size: 2rem;
				font-size: 1em;
				font-family: "Fago";
				text-transform: none;
				margin: 0.5rem 0;
				hyphens: auto;
			}
		}
	}
	.form-link {
		display: block;
		background-color: $light;
		padding: 20px 20px 20px 80px;
		font-family: "FagoBold";
		font-size: 1.25rem;
		position: relative;
		
		&:before {
			content: "";
			width: 65px;
			height: 100%;
			background: url(/img/icon-koffer.svg) no-repeat center;
			position: absolute;
			left: 10px;
			top: 0;
			bottom: 0;
		}
	}

	.mobule._text {
		margin-top: -3em;
		@include media-breakpoint-up(md) {
			margin-top: -2em;
		}
		
	}

	// Button 
	.btn.dropdown-toggle.btn-light.bs-placeholder {
		background-color: white;
		//border-color: #999;
		border-width: 1px;
		border-color: $dropdown-outline-color;
			
	}

	div.dropup > div.dropdown-menu.show {
		border-top: 1px solid #e0ddd9;
	}

	.btn.dropdown-toggle {
		background-color: white;
		//border-color: #999;
		border-width: 1px;
		border-color: $dropdown-outline-color;
		text-transform: none!important;
		font-weight: normal!important;
	}

	h2.subtitle {
		margin-bottom: 2em;
	}

	#stellenangebote-list-snippet, #stellenangebote-editable {
		margin-top: 1.5em;
		margin-bottom: 1.5em;
	}

	#stellenangebote-editable {
		margin-top: 3em;
	}

	.filter-controls {
		padding-top: 2em;
	}

	.bootstrap-select .dropdown-menu li a {
		padding-left: 0.5em;
	}

	.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
		white-space: normal;
		line-height: 2;
		padding-top: 0.07em;
		letter-spacing: normal;
		font-size: 16px;
	  }

	  .dropdown-menu.show > .inner{
		
		/*border-left: 1px solid $dropdown-outline-color;
		border-right: 1px solid $dropdown-outline-color;
		border-bottom: 1px solid $dropdown-outline-color;
		*/
		
	  }

	  .bootstrap-select.show-tick .dropdown-menu li a span.text {
		font-weight: normal;
		line-height: 2;
		font-size: 16px;
	  }

	  .bootstrap-select > .dropdown-toggle::after {

		margin-top: -1px;
	
	}

	.dropdown-toggle::after, .dropup .dropdown-toggle::after {
	
		display: inline-block;
		content: "";
		width: 46px;
		height: 46px;
		z-index: 200;
		border: 0 solid #fff;
		border-left-color: rgb(255, 255, 255);
		border-left-style: solid;
		border-left-width: 0px;
		padding-left: 6px;
		padding-right: 6px;
		margin-left: .255em;
		vertical-align: .255em;
		border-bottom: 0;
		border-left: 0!important;
	
	}

	.dropdown-toggle::after {
	
		background: url(/img/form-arrow-down.svg);
			background-position-x: 0%;
			background-position-y: 0%;
			background-repeat: repeat;
			background-clip: border-box;
		background-position-y: center;
		background-position-x: center;
		background-repeat: no-repeat;
		background-clip: content-box;
	
	}

	.dropup .dropdown-toggle::after {
		background: url(/img/form-arrow-up.svg);
		background-position-x: 0%;
			background-position-y: 0%;
			background-repeat: repeat;
			background-clip: border-box;
		background-position-y: center;
		background-position-x: center;
		background-repeat: no-repeat;
		background-clip: content-box;
	}

	.dropdown-toggle::after {
	
		margin-left: .255em;
		vertical-align: .255em;
		border-bottom: 0;
		border-left: 0!important;

	
	}
	
}




