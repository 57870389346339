@import "variables";
@import "bootstrap";
@import "bootstrap-select";
@import "../../node_modules/bootstrap-multiselect/dist/css/bootstrap-multiselect.css";

@import "~slick-carousel/slick/slick";
@import "slickCarousel"; //lokales theme

//@import "bootstrap-select";
//@import "~bootstrap-select/sass/bootstrap-select";

$pswp__assets-path: '/img/photoswipe/'; // path to skin assets folder (preloader, PNG and SVG sprite)
@import "~photoswipe/src/css/main.scss";
@import "~photoswipe/src/css/default-skin/default-skin.scss";

@import "mixins";

@import "basic";
@import "fonts";
@import "type";
@import "buttons";
@import "footer";
@import "../../src/Bit/CookieBundle/Resources/assets/scss/cookies";
@import "forms";
@import "list";
@import "search";
@import "typeahead";
@import "stellenangebote";

@import "headroom";
@import "navigation";
@import "datepicker";
@import "scrollTop";
@import "readspeaker";

@import "modules/buehne";
@import "modules/slider";
@import "modules/linkleiste";
@import "modules/teasercarousel";
@import "modules/trenner";
@import "modules/aktuelles";
@import "modules/special";
@import "modules/zitat";
@import "modules/text";
@import "modules/ansprechpartner";
@import "modules/akkordion";
@import "modules/karte";
@import "modules/downloads";
@import "modules/bildergalerie";
@import "modules/video";

.stellenangebote { 
    .bootstrap-select {
        position: relative!important;
    }

    .dropdown-menu.show {
        left: 16px;
    }
}