._aktuelles {
	margin-top: 120px;
	margin-bottom: 120px;
	
	@include media-breakpoint-up(md) {
		.slick-slide {
			margin: 0 15px;
		}
	}
	.headline {
		border-bottom: 1px solid $secondary;
		display: flex;
		align-items: center;
		margin-bottom: 35px;
		padding-bottom: 5px;
		text-transform: uppercase;
		
		img {
			margin-right: 1.5rem;
			@include media-breakpoint-down(xs) {
				display: none;
			}
		}
	}
	.item {
		display: flex;
		min-height: 222px;
		@include media-breakpoint-down(xs) {
			flex-direction: column;
		}
		
		.img {
			@include media-breakpoint-down(xs) {
				margin-bottom: 20px;
				text-align: center;
				
				img {
					display: inline-block;
					border-radius: 111px;
				}
			}
			@include media-breakpoint-up(sm) {
				margin-right: 40px;
			}
		}
		.body {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
			@include media-breakpoint-down(xs) {
				text-align: center;
			}
		}
		.title {
			margin-bottom: 20px;
			font-family: 'FagoBold';
		}
		.btn {
			align-self: center;
			@include media-breakpoint-up(sm) {
				align-self: flex-start;
			}
		}
	}
}