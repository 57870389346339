.list-group-item {
	border-bottom-width: 1px;
	@include mobile {
		padding-left: 0;
		padding-right: 0;
	}
	
	&:last-child {
		border-bottom-width: 0;
	}
}

.pagination {
	margin: 0;
	justify-content: center;
}
.paging {
	margin-top: 25px;
	margin-bottom: 25px;
}

#searchresults {
	margin-top: 50px;
    margin-bottom: 50px;
	
	.paging {
		font-size: 1.125rem;
	}
	.liste {
		h1 {
			margin-bottom: 0;
		}
		p {
			margin: 0.5rem 0 0;
		}
		em {
			color: $primary;
			font-style: normal;
		}
	}
}