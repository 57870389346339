.btn {
	@media (max-width: 575px) {
		min-width: 130px;
	}
	text-transform: uppercase;
    letter-spacing: 1.7px;
	
	&.-arrow {
		position: relative;
		padding-right: 20px;
		
		&::after {
			background: url(/img/icon-more.svg) no-repeat center;
			background-size: contain;
			content: " ";
			display: inline-block;
			width: 16px;
			height: 16px;
			position: absolute;
			top: 50%;
			right: 2px;
			margin-top: -8px;
		}
		&.btn-white, &.btn-outline-white {
			&::after {
				background-image: url(/img/icon-more.svg?color=ffffff) !important;
			}
			@include hover {
				&::after {
					background-image: url(/img/icon-more.svg?color=010101) !important;
				}
			}
		}
		&.btn-primary, &.btn-outline-primary {
			&::after {
				background-image: url(/img/icon-more.svg?color=e2001a) !important;
			}
			@include hover {
				&::after {
					background-image: url(/img/icon-more.svg?color=ffffff) !important;
				}
			}
		}
		&.btn-secondary, &.btn-outline-secondary {
			&::after {
				background-image: url(/img/icon-more.svg?color=767676) !important;
			}
			@include hover {
				&::after {
					background-image: url(/img/icon-more.svg?color=ffffff) !important;
				}
			}
		}
	}
}