footer {
	background-color: $primary;
	
	.wrapper {
		display: flex;
		flex-wrap: wrap;
		min-height: 200px;
		padding-top: 40px;
		padding-bottom: 30px;
	}
	&, a {
		color: $white;
	}
	a {
		@include hover() {
			color: $white;
		}
		&.icon-sitemap {
			padding-left: 33px;
			position: relative;
			
			&::before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -14px;
				background: url(/img/icon-sitemap.svg) no-repeat center;
				width: 28px;
				height: 28px;
			}
		}
	}
	.wysiwyg {
		margin: 0 10px;
	}
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		
		li {
			display: inline-block;
			margin: 0 10px 10px;
		}
	}
}