@import "~leaflet/dist/leaflet.css";

._karte {
	margin-top: 50px;
	margin-bottom: 50px;
	
	.map {
		height: 340px;
		max-height: 80vh;
		max-width: 800px;

		&.leaflet-container a {
			color: $dark;
		}
		.leaflet-popup-content p {
			margin: 10px 0;
		}
	}
}