$primary: #e2001a;
$secondary: #767676; //custom color for buttons etc.
$light: #f7f6f5; //eg. class bg-light
$white: #fff;
$black: #000;

$theme-colors: (
	"white": $white
);

$body-color: #010101;
$link-color: $body-color;
$text-muted: #999999;

$spacer: 1.5rem; //1rem
$enable-rounded: false;
//$enable-gradients: false;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	hd: 1400px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	hd: 1170px
);

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$nav-link-padding-y: 0;

$breadcrumb-padding-y: 1rem;
$breadcrumb-bg: $light;
$breadcrumb-divider-color: $secondary;
$breadcrumb-active-color: $black;
$breadcrumb-divider: "\25CB";
$breadcrumb-margin-bottom: 80px;

//Font
$font-family-sans-serif: Fago;
$font-family-bold: "FagoBold";

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
//$font-size-lg: $font-size-base * 1.25 !default;
//$font-size-sm: $font-size-base * .875 !default;

$line-height-base: 1.6;
//$line-height-lg: 1.5;
//$line-height-sm: 1.5;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$h1-font-size-mobile: $font-size-base * 2;
$h2-font-size-mobile: $font-size-base * 1.75;
$h3-font-size-mobile: $font-size-base * 1.5;
$h4-font-size-mobile: $font-size-base * 1.25;
$h5-font-size-mobile: $font-size-base * 1.1;
$h6-font-size-mobile: $font-size-base;

$headings-margin-bottom: $spacer / 2;
$headings-font-family: $font-family-bold;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: $body-color;

$lead-font-size: 1.5rem;
$lead-font-weight: 400;

$small-font-size: $font-size-base * .8;


//Button
$btn-padding-y: .25rem;
$btn-padding-x: .5rem;

$btn-border-width: 2px;

$btn-font-weight: 700;
$btn-box-shadow: none;
//$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow: none;
//$btn-disabled-opacity:        .65 !default;
//$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

//$btn-link-disabled-color:     $gray-600 !default;


//Input
$input-padding-y: .575rem;
$input-padding-x: 1.5rem;

$input-font-size: 1.125rem;

$input-bg: #f5f4f2;
$input-disabled-bg: $input-bg;

$input-color: #000;
$input-border-width: 0;
$input-box-shadow: none;

//$input-placeholder-color:               $gray-600 !default;

//form + validation
$form-feedback-valid-color: $body-color;
$form-feedback-icon-valid: none;
$form-feedback-font-size: 0.9rem;
$form-check-input-margin-y: 0.5rem;

//listen
$list-group-item-padding-y: 1rem;
$list-group-border-width: 0;
$list-group-hover-bg: $light;

//Akkordeon
$card-border-color: $light;
$card-cap-bg: $light;

//Carousel
$carousel-control-width: 10%;
$carousel-control-icon-width: 34px;
$carousel-control-prev-icon-bg: url("/img/icon-li-subnavi-active.svg?color=ffffff");
$carousel-control-next-icon-bg: url("/img/icon-li-subnavi-active.svg?color=ffffff");
$carousel-indicator-width: 23px;
$carousel-indicator-height: 23px;
$carousel-indicator-spacer: 6px;
$carousel-indicator-hit-area-height: 0;
$carousel-indicator-transition: none;