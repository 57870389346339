._special {
	margin-top: 120px;
	margin-bottom: 120px;
	
	.container-fluid {
		display: flex;
	}
	@include ie11() {
		.iefix {
			width: 100%;
		}
	}
	.wysiwyg {
		margin-top: 30px;
		@include desktop() {
			margin-top: 0;
		}
		@include media-breakpoint-up(xl) {
			margin-left: 1.5rem;
		}
	}
}