@include desktop() {
	header {
		min-height: 331px; //damit der content nicht springt wenn elemente auf "fixed" wechseln
	}
	#offcanvas-menu {
		background-color: #fff;
		width: 100%;
		transition: all 300ms linear;

		&.headroom--unpinned {
			transform: translateY(-100%);
		}
		&.headroom--pinned {
			transform: translateY(0%);
			position: fixed;
			z-index: 2000;
			top: 0;
		}
		&.headroom--pinned:not(.headroom--top) {
			nav.menu {
				height: 80px !important;
				padding-top: 5px;
				padding-bottom: 5px;
				
				.menu-item {
					.menu-link {
						padding-top: 0;
						padding-bottom: 0;
					}
				}
			}
		}
		&.headroom--top {
			position: relative;
		}
	}
}
@include mobile() {
	header {
		min-height: 84px; //damit der content nicht springt wenn elemente auf "fixed" wechseln
	}
	#header {
		background-color: #fff;
		width: 100%;
		transition: all 300ms linear;

		&.headroom--unpinned {
			transform: translateY(-100%);
		}
		&.headroom--pinned {
			transform: translateY(0%);
			position: fixed;
			z-index: 200;
			top: 0;
		}
		&.headroom--top {
			position: static;
		}
	}
}