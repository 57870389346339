@import "~bootstrap-select/sass/bootstrap-select";

.bootstrap-select {
	& > select.mobile-device:focus + .dropdown-toggle,
	.dropdown-toggle:focus {
		outline: none !important;
	}
	.dropdown-menu {
		//min-width: 375px;
	}
}

.dropdown-menu {
	display: none;
}

.dropdown-menu.show {
	display: block;
	background-color: white;
	z-index: 1;
	border-left: 1px solid #e0ddd9;
	border-right: 1px solid #e0ddd9;
	border-bottom: 1px solid #e0ddd9;
	z-index: 1000;
}

.input-group .bootstrap-select.form-control .dropdown-toggle {
    line-height: 2.2;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    white-space: normal;
}