._ansprechpartner {
	.container-fluid {
		display: flex;
		flex-wrap: wrap;
	}
	.item {
		background-color: $white;
		display: flex;
		flex: 1 1 680px;
		align-items: center;
		padding: 20px 0;
		@include media-breakpoint-up(sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
		@include media-breakpoint-up(md) {
			height: 244px;
		}
		
		img {
			border-radius: 100px;
			margin-right: 30px;
			min-width: 200px;
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
		.text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
		}
		.name, .contact {
			font-size: $h5-font-size-mobile;
			@include desktop() {
				font-size: $h5-font-size;
			}
		}
		.mail {
			padding-right: 10px;
		}
		.phone {
			padding-right: 10px;
		}
	}
}
.red-bar ._ansprechpartner {
	margin: 0;
	
	.item {
		background-color: $primary;
		
		img {
			border: 2px solid $white;
		}
		.text {
			justify-content: flex-start;
		}
	}
}