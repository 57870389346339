#search {
	
}
#searchform {
	background-color: $light;
	margin-top: 68px;
    margin-bottom: 50px;
    font-size: 1.25rem;
	padding: 30px;
	
	#form-search {
		background-color: $white;
	}
	.facet {
		margin-left: 23px;
		
		.title {
			margin-top: 10px;
		}
		ul {
			margin: 0;
			padding: 0;
		}
		li {
			display: block;
			padding: 0 25px 5px 0;

			a {
				&.disabled {
					color: $btn-link-disabled-color;
					pointer-events: none;
					opacity: 0.65;
				}
				&.active::after {
					content: "";
					display: inline-block;
					width: 18px;
					height: 18px;
					background: url(/img/icon-check.svg) no-repeat center;
					background-size: cover;
					margin-left: 5px;
				}
			}
		}
	}
}