.form-group {
	position: relative;
}

.input-group > .form-control:not(:last-child) {
	border-right: none;
}
.input-group-text {
	background-color: $input-bg;
	border-left: none;
	padding: $input-padding-y $input-padding-x/3*2;
	
	img {
		cursor: pointer;
	}
}

.float-label {
	label {
		position: absolute;
		top: -2px;
		left: 0;
		transform: translate(1.5rem, 0.7rem) scale(1);
		transform-origin: 0 0;
		font-size: 1.125rem;
		opacity: .65;
		pointer-events: none;
		transition: transform .2s ease-out, opacity .2s linear;
		z-index: 10;
	
		&.active {
			transform: translate(1.5rem, 0.2rem) scale(0.7);
			opacity: 0.9;
			
			& + input, & + .input-group input, & + select {
				padding-top: .75rem;
				padding-bottom: 0;
			}
		}
	}
}

.custom-control-label, .form-check-label, .custom-file-label {
	font-size: 1.125rem;
}

.custom-file {
	margin-bottom: $form-group-margin-bottom;
}
.custom-file-label {
	color: rgba(0,0,0,0.65); //opacity .65 wie float-label
}