._accordion {
	margin-top: 50px;
	margin-bottom: 50px;
	font-size: 1.25rem;
	
	h2 {
		margin-bottom: 2.5rem;
	}
	.card {
		margin-bottom: 20px;
		overflow: hidden;
	}
	.card-header {
		padding-right: 3.5rem;
		
		button {
			position: relative;
			text-align: left;
			font-size: 1.25rem;
			text-transform: none;
			padding: 0;
			@include hover {
				text-decoration: none;
			}
			
			&::after {
				content: "";
				background: url(/img/icon-akkordeon-arrow.svg) no-repeat center;
				width: 26px;
				height: 26px;
				display: block;
				position: absolute;
				right: -30px;
				top: 50%;
				margin-top: -13px;
			}
		}
	}
	.card-body {
		padding-bottom: 0;
	}
}