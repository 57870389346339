._teasercarousel {
	margin-top: 120px;
	margin-bottom: 120px;
	text-align: center;
	
	@media (min-width: 768px) {
		.slick-slide {
			margin: 0 15px;
		}
	}
	.item {
		position: relative;
		height: 530px;
		border: 1px solid $gray-500;
		
		.img {
			position: relative;
			overflow: hidden;
			height: 315px;
		}
		.body {
			position: relative;
			padding-top: 60px;
		}
		.icon {
			position: absolute;
			top: -45px;
			width: 90px;
			height: 90px;
			left: 50%;
			margin-left: -45px;
		}
		.title {
			margin-bottom: 35px;
			font-family: 'FagoBold';
		}
	}
}