._linkleiste {
	background-color: $primary;
	
	&.-border {
		border-bottom: 12px solid $light;
	}
	.container-fluid {
		display: flex;
		flex-wrap: wrap;
		overflow: hidden;
		@include media-breakpoint-down(xs) {
			padding: 0;
		}
	}
	.item {
		border: 1px solid lighten($primary, 5%);
		height: 100px;
		flex-grow: 1;
		display: flex;
		align-items: center;
		margin-top: -1px;
		margin-left: -1px;
		padding: 0 15px;
		@include media-breakpoint-up(sm) {
			justify-content: center;
		}
		
		&:last-child {
			border-right: 1px solid lighten($primary, 5%);
		}
		img {
			margin-right: 20px;
		}
		a {
			font-family: "FagoBold";
			font-size: $h5-font-size-mobile;
			@include desktop() {
				font-size: $h5-font-size;
			}
		}
	}
}