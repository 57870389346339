@include mobile() {
	h1, .h1 { @include font-size($h1-font-size-mobile); }
	h2, .h2 { @include font-size($h2-font-size-mobile); }
	h3, .h3 { @include font-size($h3-font-size-mobile); }
	h4, .h4 { @include font-size($h4-font-size-mobile); }
	h5, .h5 { @include font-size($h5-font-size-mobile); }
	h6, .h6 { @include font-size($h6-font-size-mobile); }
}

h1, .h1 {
	margin-bottom: 2rem;
	
	&.-border {
		position: relative;

		&::after {
			content: "";
			position: absolute;
			bottom: -10px;
			left: 0;
			width: 70px;
			height: 5px;
			background-color: $primary;
		}
	}
}

b, strong {
	font-family: "FagoBold";
}