._trenner {
	position: relative;
	height: 24px;
	background-color: $light;
	margin: 75px 0;
	
	img {
		position: absolute;
		left: 50%;
		margin-left: -48px;
		top: -38px;
		height: 98px;
		width: 96px;
	}
}