#scrollTop {
    width: 66px;
	height: 66px;
    position: fixed;
    bottom: 75px;
    right: 20px;
    display: none;
    z-index: 1000;
    cursor: pointer;
    border: 2px solid $secondary;
	background-color: rgba($white, 0.75);
	border-radius: 33px;
	
    &::after {
		content: "";
		display: block;
        width: 44px;
		height: 44px;
		left: 9px;
		top: 7px;
		position: absolute;
        background: url('/img/icon-li-subnavi-active.svg?color=767676') no-repeat center;
		transform-origin: center;
		transform: rotate(0.75turn);
	}
}