@font-face {
	font-family: "Fago";
	src: url("/fonts/FagoWeb.eot"); /* IE9 Compat Modes */
	src: url("/fonts/FagoWeb.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
		url("/fonts/FagoWeb.otf") format("opentype"), /* Open Type Font */
		url("/fonts/FagoWeb.svg") format("svg"), /* Legacy iOS */
		url("/fonts/FagoWeb.ttf") format("truetype"), /* Safari, Android, iOS */
		url("/fonts/FagoWeb.woff") format("woff"), /* Modern Browsers */
		url("/fonts/FagoWeb.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "FagoBold";
	src: url("/fonts/FagoWeb-Bold.eot"); /* IE9 Compat Modes */
	src: url("/fonts/FagoWeb-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
		url("/fonts/FagoWeb-Bold.otf") format("opentype"), /* Open Type Font */
		url("/fonts/FagoWeb-Bold.svg") format("svg"), /* Legacy iOS */
		url("/fonts/FagoWeb-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
		url("/fonts/FagoWeb-Bold.woff") format("woff"), /* Modern Browsers */
		url("/fonts/FagoWeb-Bold.woff2") format("woff2"); /* Modern Browsers */
	font-weight: bold;
	font-style: normal;
}
.regular {
	font-family: 'Fago' !important;
}
.bold {
	font-family: 'FagoBold' !important;
}