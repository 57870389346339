._zitat {
	margin-top: 50px;
	margin-bottom: 50px;
	padding-top: 50px;
	padding-bottom: 50px;
	
	&.-home {
		background-color: $light;
		
		.text-col {
			@include mobile() {
				text-align: center;
			}
		}
		.img-col {

		}
		.wysiwyg {
			margin-left: 0;
		}
		cite {
			@include desktop() {
				padding-top: 25px;
			}
		}
	}
	.text-col {
		order: 2;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include desktop() {
			order: 1;
		}
	}
	.img-col {
		order: 1;
		text-align: center;
		@include mobile() {
			padding-bottom: 25px;
		}
		@include desktop() {
			order: 2;
		}
		
		img {
			border-radius: 175px;
		}
	}
	h2, .h2 {
		margin-bottom: 1.25rem;
		@include desktop() {
			margin-bottom: 2.5rem;
		}
	}
	.wysiwyg {
		position: relative;
		font-style: italic;
		font-size: $h5-font-size;
		margin: 0 0 0 60px;
		
		&::before {
			content: " ";
			background: url(/img/icon-quote.svg) no-repeat center;
			width: 40px;
			height: 40px;
			position: absolute;
			left: -60px;
			top: 0;
		}
	}
	cite {
		display: block;
		font-style: normal;
		text-transform: uppercase;
		padding-top: 10px;
	}
}