._downloads {
	margin-top: 50px;
	margin-bottom: 50px;
	font-size: 1.25rem;
	
	&.-border {
		border: 1px solid #ababab;
		
		.wrapper {
			margin: 25px;
		}
	}
	.wrapper {
		position: relative;
		
		.icon {
			position: absolute;
			top: -10px;
			right: -10px;
		}
	}
	.downloads {
		list-style: none;
		margin: 0;
		padding: 0;
		
		li {
			display: block;
			margin-top: 10px;
			
			&, a {
				line-height: 24px;
			}
			a {
				color: $primary;
			}
		}
	}
}

aside {
	._downloads {
		.wrapper {
			margin: 25px;
		}
	}
}