._video {
	margin-top: 50px;
	margin-bottom: 50px;
	width: 100%;
	position: relative;
	padding-top: 100% / (16/9);

	&._4-3 {
		padding-top: 100% / (4/3);
	}
	&._3-2 {
		padding-top: 100% / (3/2);
	}
	.wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		& > div {
			height: 100%;
		}
	}
}